import { Alert, Box, Divider, FormControl, FormLabel, Typography } from "@mui/material";
import { 
  ExternalMeetingInfo, MeetingPollPriority, MeetingQuestionAnswerSubmission, SelectedSlots 
} from "../../../store";
import colors from "../../../colors";
import { DateTime } from "luxon";
import { CabButton, CabIcon } from "@CabComponents";
import { IoAdd } from 'react-icons/io5';
import { getAnswerString } from "../../../utils/scheduleUtils";

export type SuccessProps = {
  poll: ExternalMeetingInfo | undefined
  answers: { [email: string]: MeetingQuestionAnswerSubmission[] };
  votedSlots: { [email: string]: Map<number, SelectedSlots[number] | null> };
  participants: {email: string, name: string}[];
  onEditSelections: () => void;
};


const Success  = ({poll, answers, votedSlots, participants, onEditSelections}: SuccessProps) => {
  return (
    <Box>
      <Box display='flex' flexDirection='column' gap={4}>
        <Typography variant="h1" lineHeight={1} fontSize={38}>
          Thanks for submitting your selections!
        </Typography>
        <Typography variant="h5">
          <b>{poll?.create_user.first_name} {poll?.create_user.last_name}</b>&nbsp;
          will send a meeting invite when all the results are in.
        </Typography>
      </Box>
      <Box padding={5} sx={{backgroundColor: colors.white900}} border={'1px solid'} 
        borderColor={colors.black200} borderRadius={'12px'} marginTop={4} marginBottom={5}>
        <Box display='flex' flexDirection={{xs: 'column', md: 'row'}} sx={{justifyContent: 'space-between'}}>
          <Typography variant="h1" lineHeight={'36px'} fontSize={26}>
            Your Selections
          </Typography>
          <Box display='flex' gap={1} flexDirection={{xs: 'column', lg: 'row'}}>
            <CabButton onClick={onEditSelections} sx={{maxWidth: '160px'}}>Edit Selections</CabButton>
            <CabButton
              sx={{maxWidth: '160px'}}
              onClick={() => window.location.reload()}
              buttonType="tertiary"
              color='primary'
              icon={<CabIcon Icon={IoAdd} alt='Add'/>}
            >
              Add Attendee
            </CabButton>
          </Box>
        </Box>

        {participants.map(({ name, email }, i) => {
          const filteredVotes = Array.from(votedSlots[email]?.values() || []).filter(
            slot => (slot?.priority != null && slot != null)
          ) as SelectedSlots;
          const sortedVotes = filteredVotes.sort((a, b) => (a.start > b.start) ? 1 : -1);
          const dates = [...new Set(sortedVotes.map(slot =>  
            slot.start.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric'})))];

          return (
            <Box key={email}>
              {i !== 0 && <Divider sx={{ marginTop: 4 }} />}
              <Typography marginTop={'12px'} variant="h5" lineHeight={'24px'} fontSize={16}>
                {email}
              </Typography>
              {dates.length ? (
                <Box>
                  <Box display="flex" flexDirection="row" gap={3} marginTop={4}>
                    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                      <Box sx={{ backgroundColor: colors.navyPrimary }} width={22} height={22} borderRadius={2} />
                      Available
                    </Box>
                    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                      <Box sx={{ borderStyle: 'solid', borderWidth: 2, borderColor: colors.navyPrimary }}
                        width={22} height={22} borderRadius={2} />
                      Potentially Available
                    </Box>
                  </Box>
                  <Box>
                    {dates.map(date => {
                      return (
                        <Box key={date} marginTop={2}>
                          <Typography variant="body1" marginBottom={2}>{date}</Typography>
                          <Box display='flex' flexWrap='wrap' gap={1}>
                            {sortedVotes.filter(slot => 
                              slot.start.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric'}) === date)
                              .map(dateSlot => {
                                return (
                                  <TimeSlot
                                    key={dateSlot.start.toString()}
                                    priority={dateSlot.priority || MeetingPollPriority.AVAILABLE}
                                    start={dateSlot.start}
                                    end={dateSlot.end}
                                  />
                                );
                              })}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                <Alert severity="warning" sx={{marginTop: 2}}> None of the provided times work </Alert>
              )}
              {poll?.questions && poll.questions?.length > 0 && (
                <Box marginTop={2}>
                  <Box>
                    <QuestionResponses questions={poll.questions} answers={answers[email] || []} />
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Success;

type TimeSlotProps = {
  priority: number;
  start: DateTime;
  end: DateTime;
};

const TimeSlot = ({priority, start, end}: TimeSlotProps) => {
  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: colors.white900,
      borderColor: colors.black200,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 1,
      paddingLeft: 1,
      paddingRight: 1,
      justifyContent: 'space-between',
      textAlign: 'center',
      color: colors.black700,
      ...(priority === 1 ? {
        backgroundColor: colors.navyPrimary,
        borderColor: colors.navyPrimary,
        color: colors.white900,
      } : {}),
      ...(priority === 2 ? {
        backgroundColor: colors.white900,
        borderColor: colors.navyPrimary,
        color: colors.navyPrimary,
      } : {}),

    }} >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingTop={1}
        paddingBottom={1}
        width={80}
        height={58}
      >
        <Box fontWeight={500}>{start.toFormat('h:mma').toLowerCase()}</Box>
        <Box fontWeight={500}>{end.toFormat('h:mma').toLowerCase()}</Box>
      </Box>
    </Box>
  );
};

type QuestionProps = {
  questions: ExternalMeetingInfo['questions'] | undefined
  answers: MeetingQuestionAnswerSubmission[];
};

const QuestionResponses = ({ questions, answers }: QuestionProps) => {
  return (
    <Box marginTop={2}>
      {questions?.slice().sort((a, b) => (a?.order || 0) - (b?.order || 0)).map((question) => {
        const answer = answers.find(a => a.question === question.id);
        return (
          <FormControl key={question.id} fullWidth>
            <FormLabel sx={{marginTop: 2}}>{question.required ? question.title + ' *' : question.title}</FormLabel>
            <Box sx={{width: '100%'}}>
              <Typography marginTop={2} fontWeight={600}>
                {getAnswerString(answer, question, '- no answer -')}
              </Typography>
            </Box>
          </FormControl>
        );
      })}
    </Box>
  );
};
