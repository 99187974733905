import {  Alert, Box, FormControl, FormLabel, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import MeetingParticipantSelector from "../../../components/Schedule/MeetingParticipantSelector";
import { CabButton, CabIcon, CabModal, CabTextInput } from "@CabComponents";
import { useForm, Controller } from "react-hook-form";
import { emailRegex } from "../../../constants";
import { IoAddOutline } from 'react-icons/io5';
import { Attendee } from "../PollVotingParticipant";
import colors from "../../../colors";


export type ParticipantSelectorProps = {
  from: string
  participants: { name: string; email: string; first_response_date: string | null }[]
  handleSelectParticipants: (participants: { name: string; email: string }[]) => void,
  selectedAttendees: Attendee[];
  addedParticipants: { 
    name: string; email: string; first_response_date: string | null, no_times_comment: string | null
  }[]
  setAddedParticipants: (participants: { 
    name: string; email: string; first_response_date: string | null, no_times_comment: string | null
  }[]) => void;
  allowAddAttendees: boolean;
};

const ParticipantSelector = ({ 
  participants, from, handleSelectParticipants, selectedAttendees, addedParticipants, setAddedParticipants,
  allowAddAttendees
}: ParticipantSelectorProps
): ReactElement => {
  const [attendeeModalOpen, setAttendeeModalOpen] = useState(false);

  const handleClickParticipant = (participant: { name: string; email: string }) => {
    let tempParticipants: { name: string; email: string }[] = [...selectedAttendees];
    const index = tempParticipants.findIndex(attendee => attendee.email === participant.email);
    if (index === -1) {
      tempParticipants = [
        ...tempParticipants,
        {name: participant.name || '', email: participant.email || '' },
      ];
    } else {
      tempParticipants.splice(index, 1);
    }
    handleSelectParticipants(tempParticipants);
  };

  const sortedParticipants = participants.sort((a, b) => (a?.name || a.email).localeCompare((b?.name || b.email)));

  const {control, handleSubmit, reset, formState, setFocus} =  useForm({defaultValues: {name: "", email: ""}});

  const submitAttendee = ({email, name}: {email: string, name: string}) => {
    setAttendeeModalOpen(false);
    reset({name: "", email: ""});
    setFocus('name');
    setAddedParticipants([
      ...addedParticipants, 
      // {id: (-1 - addedParticipants.length), name, email, first_response_date: null}
      { name, email, first_response_date: null, no_times_comment: null },
    ]);
    // handleSelectParticipants([...selectedAttendees, {name, email}]);
    handleClickParticipant({ email, name });
  };

  const cancelModal = () => {
    setAttendeeModalOpen(false);
    reset({name: "", email: ""});
  };

  const handleEnterSubmit = (key: string) => {
    if (key === 'Enter' && formState.isValid) {
      handleSubmit(submitAttendee)();
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={4}>
      <Typography variant="subtitle2" sx={{fontSize: 56}}>Hello!</Typography>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography sx={{maxWidth: 600, fontSize: 18}}>
          <strong>{from}</strong> has invited you to choose preferred times for this meeting.
        </Typography>
        <Typography sx={{maxWidth: 600, fontSize: 18}}>
          Please select yourself, or the people you support with scheduling:
        </Typography>
      </Box>
      <Box width='100%' maxWidth={'780px'}>
        <MeetingParticipantSelector 
          onClick={handleClickParticipant} 
          selectedParticipants={selectedAttendees}
          participants={[...sortedParticipants, ...addedParticipants]}
        />
        {allowAddAttendees && (
          <>
            <Typography variant="body2" color={colors.black700} marginLeft={1} marginTop={2}>
              Missing someone?
            </Typography>
            <CabButton 
              buttonType="text"
              color="primary"
              onClick={() => setAttendeeModalOpen(true)}
              icon={<CabIcon Icon={IoAddOutline} />}
            >
              Add Attendee
            </CabButton>
          </>
        )}
      </Box>
      <CabModal 
        open={attendeeModalOpen} 
        title="New Attendee"
        closeIcon={true}
        onClose={() => cancelModal()}
        actionButtons={<>
          <CabButton disabled={!formState.isValid} onClick={handleSubmit(submitAttendee)}>Submit</CabButton>
          <CabButton onClick={() => cancelModal()} buttonType="tertiary">Cancel</CabButton>
        </>}
      >
        <Box maxWidth={'300px'} gap={2} display='flex' flexDirection='column'>
          <Typography sx={{maxWidth: 533, fontSize: 18}}>
            Enter new attendee details
          </Typography>
          <FormControl sx={{ flex: 1, width: '100%' }}>
            <FormLabel>Name</FormLabel>
            <Controller 
              name="name"
              control={control}
              render={({field: { ref, ...field }}) => {
                return <CabTextInput
                  {...field}
                  inputRef={ref}
                  onKeyDown={(e) => handleEnterSubmit(e.key)}
                  type="text" />;
              }}
            />
          </FormControl>
          <FormControl sx={{ flex: 1, width: '100%' }}>
            <FormLabel>Email*</FormLabel>
            <Controller 
              name="email"
              rules={{
                required: {
                  value: true,
                  message: "Email is required"
                },
                pattern: {
                  value: emailRegex,
                  message: "Invalid Email"
                },
                validate: (email) => {
                  if ([...participants, ...addedParticipants]
                    .find(p => p.email.toLowerCase() === email.toLowerCase())) {
                    return 'There is already an attendee with that email address';
                  }
                  return true;
                },
              }}
              control={control}
              render={({field: { ref, ...field }, fieldState}) => {
                return <><CabTextInput
                  {...field}
                  inputRef={ref}
                  onKeyDown={(e) => handleEnterSubmit(e.key)}
                  type="text"
                  required
                />
                {fieldState.error && fieldState.error.type === "validate" && (
                  <Alert severity="error" sx={{ marginTop: 1 }}>
                    {fieldState.error.message || "Invalid Email"}
                  </Alert>
                )}
                </>;
              }}
            />
          </FormControl>
        </Box>
      </CabModal>
    </Box>
  );
};

export default ParticipantSelector;