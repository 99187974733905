import { CabTextInput } from "@CabComponents/CabTextInput";
import { Alert, Box, Divider, FormControl, FormLabel, Typography } from "@mui/material";
import { Fragment, ReactElement, useEffect } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { ExternalMeetingInfo, MeetingQuestion, MeetingQuestionAnswerSubmission } from "../../../store";
import { useMountEffect } from "../../../utils/hooks";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { QuestionType } from "../../../store/schedule/types";


interface AnswerForm {
  [email: string]: MeetingQuestionAnswerSubmission[];
}

export type QuestionResponseProps = {
  poll: ExternalMeetingInfo | undefined;
  participants: {email: string, name: string}[];
  defaultAnswers: { [email: string]: MeetingQuestionAnswerSubmission[] };
  onSubmitAnswers: (answers: { [email: string]: MeetingQuestionAnswerSubmission[] }) => void;
  setQuestionResponseValid: (isValid: boolean) => void;
};

const QuestionResponse  = ({
  poll, participants, onSubmitAnswers, defaultAnswers, setQuestionResponseValid
}: QuestionResponseProps): ReactElement => {
  const reformattedDefaultAnswers = Object.entries(defaultAnswers)
    .map(([key, val]) => ({ [key.replaceAll('.', ':')]: val }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  const { control, watch, formState, setFocus } = useForm<AnswerForm>({ defaultValues: reformattedDefaultAnswers });

  const answers = watch();

  useMountEffect(() => {
    setFocus(`${participants[0].email.replaceAll('.', ':')}.${0}.text`);
  });

  useEffect(() => {
    setQuestionResponseValid(formState.isValid);
  }, [formState.isValid, setQuestionResponseValid]);

  useEffect(() => {
    onSubmitAnswers(
      Object.entries(answers)
        .map(([key, val]) => ({ [key.replaceAll(':', '.')]: val }))
        .reduce((a, b) => ({ ...a, ...b }), {})
    );
  // NOTE: Only update when hashable changes to answers occurs
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(answers)]);

  return (
    <Box>
      <Typography variant="h1" fontSize={30} lineHeight={'41px'} marginBottom={4}>
        Please answer the following questions
      </Typography>
      <Box display="flex" flexDirection="column" gap={4}>
        {participants.map((participant, index) => (
          <Box key={participant.email} display='flex' flexDirection='column' gap={2} maxWidth={'600px'}>
            {index !== 0 && <Divider sx={{ marginBottom: 1 }} />}
            <Typography variant="h2" fontSize={20}>
              {participant.name || participant.email}
            </Typography>
            <Box display='flex' flexDirection='column' rowGap={3} maxHeight={'50vh'} overflow='auto'>
              {poll?.questions?.slice().sort((a, b) => (a?.order || 0) - (b?.order || 0)).map((question, idx) => (
                <Fragment key={question.id}>
                  <Question
                    question={question}
                    control={control}
                    participant={participant}
                    idx={idx}
                  />
                  <Controller
                    control={control}
                    name={`${participant.email.replaceAll('.', ':')}.${idx}.question`}
                    defaultValue={question.id}
                    render={() => <></>}
                  />
                  <Controller
                    control={control}
                    name={`${participant.email.replaceAll('.', ':')}.${idx}.id`}
                    defaultValue={-1}
                    render={() => <></>}
                  />
                </Fragment>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default QuestionResponse;

type QuestionProps = {
  question: MeetingQuestion
  control: Control<AnswerForm>;
  participant: {
    email: string;
    name: string;
  };
  idx: number;
};

const Question = ({ question, control, participant, idx }: QuestionProps) => {
  return (
    <FormControl fullWidth>
      <FormLabel>{question.required ? question.title + ' *' : question.title}</FormLabel>
      {question.question_type === QuestionType.TEXT ? (
        <Controller
          control={control}
          name={`${participant.email.replaceAll('.', ':')}.${idx}.text`}
          rules={{
            required: question.required,
            maxLength: 4000
          }}
          render={({field: {onChange, value, ref}, fieldState: {error, invalid}}) => <Box sx={{width: '100%'}}>
            <CabTextInput
              multiline
              fullWidth
              minRows={1}
              maxRows={6}
              required={question.required}
              inputRef={ref}
              value={value}
              onChange={onChange}
            />
            <div>
              {error &&
                <Alert severity="error" sx={{ marginTop: 1 }}>
                  {error?.type === "required" && "This field is required"}
                  {error?.type === "maxLength" && "This field should be less that 4000 characters"}
                </Alert>
              }
            </div>
          </Box>} 
        
        />
      ) : (
        <Controller
          control={control}
          name={`${participant.email.replaceAll('.', ':')}.${idx}.options`}
          rules={{
            required: question.required,
          }}
          render={({field: {onChange, value}}) => <Box sx={{width: '100%'}}>
            <CabAutocomplete<number, never>
              value={question.question_type === QuestionType.MULTI_SELECT 
                ? value ?? [] 
                : value && value.length ? value[0] : -1}
              multiple={question.question_type === QuestionType.MULTI_SELECT}
              placeholder={ !value || value.length === 0 ? "Select an answer" : ""}
              options={question.options ? question.options.map(option => ({
                value: option.id,
                label: option.name
              })) : []}
              // This is to assure we always return an array
              onChange={(v) => onChange(!v ? [] : v instanceof Array ? v : [v])}
            />
          </Box>} 
        
        />
      )}
    </FormControl>
  );
};
