import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { Box, Typography } from "@mui/material";
import colors from "../../../colors";


export type MeetingParticipantSelectorProps = {
  participants: { name: string; email: string; first_response_date: string | null }[];
  selectedParticipants: { name: string; email: string }[];
  onClick: (participant: { name: string; email: string }) => void;
};

const MeetingParticipantSelector = ({participants, onClick, selectedParticipants}: MeetingParticipantSelectorProps) => (
  <Box gap={1} display='flex' flexWrap='wrap' flexDirection={participants.length <= 8 ? 'column' : 'row'}>
    {participants.map(participant => {
      const displayName = participant.name ? participant.name : participant.email || '';
      const selected = selectedParticipants.find(attendee => attendee.email === participant.email);

      return (
        <Box
          maxWidth={350}
          minWidth={250}
          key={participant.email}
          onClick={() => onClick(participant)}
          sx={{ cursor: "pointer" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%", padding: 2, height: 43, borderRadius: 1, backgroundColor: colors.white800,
              border: selected ? 2 : 0, 
              "&:hover": { backgroundColor: colors.white700 },
            }}
          >
            <CabTooltip title={displayName} placement="right">
              <Typography sx={{
                fontWeight: participant.first_response_date ? "initial" : 600, color: colors.black800,
                maxWidth: 150, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
              }}>
                {displayName}
              </Typography>
            </CabTooltip>
            {participant.first_response_date &&
              <CabTooltip title="Select this person to edit availability" placement="top" wrapWithSpan>
                <CabToggleChip 
                  label='Responded' sx={{fontSize: 10, height: 18, paddingLeft: '4px', paddingRight: '4px'}}
                />
              </CabTooltip>
            }
          </Box>
        </Box>
      );
    })}
  </Box>
);

export default MeetingParticipantSelector;
